export const COURIER_SERVICE_LOGIN = '__c__l__a__';
export const AUTH_USER_CG_TOKEN_KEY = `${COURIER_SERVICE_LOGIN}cognito_key`;

export const COLOR = {
  blue: 'rgb(0,0,74)',
};

export const SHIPMENT_STATUS = {
  BOOKED: "Booked",
  CANCELLED: "Cancelled",
  DAMAGE: "Damage",
  DELIVERED: "Delivered",
  HOLD: "Hold",
  IN_TRANSIT: "In Transit",
  LOST: "Lost",
  MISS_ROUTE: "Miss Route",
  NDR: "Ndr",
  OUT_FOR_PICKUP: "Out For Pickup",
  OUT_FOR_DELIVERY: "Out For Delivery",
  PROCESSING: "Processing",
  RETURN_BOOKED: "Return Booked",
  RETURN_CANCELLED: "Return Cancelled",
  RETURN_DELIVERED: "Return Delivered",
  RETURN_IN_TRANSIT: "Return In Transit",
  RETURN_NDR: "Return Ndr",
  RETURN_PROCESSING: "Return Processing",
  RETURN_OUT_FOR_DELIVERY: "Return Out For Delivery",
  REVERSE_BOOKED: "Reverse Booked",
  REVERSE_DELIVERED: "Reverse Delivered",
  REVERSE_IN_TRANSIT: "Reverse In Transit",
  REVERSE_OUT_FOR_DELIVERY: "Reverse Out For Delivery",
  REVERSE_OUT_FOR_PICKUP: "Reverse Out For Pickup",
  ERROR: "ERROR",
};